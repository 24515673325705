
import React from 'react'
import me from '../../../images/khalil-headshot.png'
import { SolidBookResourceCard } from '../../resources'
import "../styles/Home.sass"

class HomeComponentLeft extends React.Component {
  render () {
    return (
      <div className="home-column-left">
        <div className="intro">
          <div className="intro-picture-container">
            <img src={me}/>
          </div>
        </div>
        
        {/* <SocialLinks 
          github={{ showDataCount: false }}
          twitter={{ showDataCount: false }}
        /> */}
        {/* <Currently/> */}
        <br/>
        {/* <SolidBookResourceCard/> */}
      </div>
    )
  }
}

export default HomeComponentLeft;