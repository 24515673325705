import React from "react";
import Layout from "../components/shared/layout";
import { StaticQuery, graphql } from "gatsby";
import {
  Hero,
  RecentArticles,
  RecentWikiEdits,
  RecentBlogs,
} from "../components/home";
import { SubscribeForm } from "../components/subscribe";
import Resources from "../components/home/components/Resources";
import prose from "../assets/prose";

const IndexPage = ({ title, description }) => (
  <Layout
    darkMode={false}
    newsletterContent={
      <>
        <h2>
          Master the <span className="special-green">essential 80-20</span>.
          Learn to write scalable, testable software.
        </h2>
        {prose.main.join}
      </>
    }
    seo={{
      title,
      exactTitle: true,
      description,
      keywords: [
        "advanced",
        "typescript",
        "nodejs",
        "best practices",
        "javascript",
      ],
    }}
  >
    <div className="home">
      <Hero />
      <br />
      <Resources displayHeader={true}/>
      <RecentArticles />
      {/* <SolidBookResourceCard/> */}
      <br />
      <hr />
      <br />
      <SubscribeForm />
      <br />
    </div>
  </Layout>
);

export default () => (
  <StaticQuery
    query={graphql`
      query IndexPageQuery {
        site {
          siteMetadata {
            title
            description
            twitter
            author
            logo
            siteUrl
          }
        }
      }
    `}
    render={(data) => {
      const { siteMetadata } = data.site;
      return <IndexPage {...siteMetadata} />;
    }}
  />
);
